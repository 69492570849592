body {
	margin: 0;
}

body,
html,
#root {
	height: 100%;
	overflow: hidden;
	user-select: none;
}

* {
	font-family: 'ZwoLF SemiLight', sans-serif;
	font-weight: normal;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

textarea:focus, 
input:focus,
button:focus,
select:focus {
    outline: none;
}

select {
	appearance: none;
	text-align-last: right;
}

option { 
	direction: rtl; 
}

::-webkit-scrollbar {
	height: 16px; /* height of the entire scrollbar */
	width: 16px; /* width of the entire scrollbar */
	background: transparent;
}

::-webkit-scrollbar-track-piece {
	background: transparent !important;
	-webkit-appearance: none !important;
}

::-webkit-scrollbar-thumb {
	background-color: white;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 6px solid black;  /* creates padding around scroll thumb */
}

.error-message {
	display: block;
	position: fixed;
	bottom: 20px;
	left: 0;
	right: 0;
	min-width: 400px;
	max-width: 764px;
	margin: 10px 20px;
	margin: auto;
	padding: 10px;
	font-size: 16px;
	text-align: center;
	color: black;
	background-color: #FF0600;
}
  